// import { Tooltip, Toast, Popover, Modal, Collapse } from 'bootstrap'
import { Modal } from 'bootstrap'
// import Swal from 'sweetalert2'
window.bootstrap = require('bootstrap');
// const bsCollapse = new bootstrap.Collapse('#myCollapse', { toggle: true })
// const collapseElementList = document.querySelectorAll('.collapse')

// document.querySelectorAll('button[data-sa-toggle=modal]').forEach(item => {
//     item.addEventListener("click", (el)=>{
//         Swal.fire({
//             title: 'Error!',
//             text: el.target.dataset.message,
//             icon: 'error',
//             confirmButtonText: 'Cool',
//             template: '#my-template'
//         })
//     })
// });